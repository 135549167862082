import * as React from "react"
import { Description } from "src/components/Description"
import { Header } from 'src/components/Header'
import { HR } from "src/components/HR"
import { PrivacyModal } from "src/components/Privacy"
import { ProgressBar } from "src/components/ProgressBar"
import { Survey } from "src/components/Survey"

import * as Styles from './styles.module.scss'

// markup
const IndexPage = () => {
  return (
    <main className={Styles.main}>
      <Header />
      <HR />
      <Description/>
      <HR/>
      <Survey />
      <PrivacyModal />
      <ProgressBar/>
    </main>
  )
}

export default IndexPage