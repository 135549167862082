import React from 'react'



export const HR = (props) => {
    return <div style={{
        height: "1px",
        width: "100%",
        marginTop: "20px",
        marginBottom: "20px",
        borderBottom: "1px solid black"
    }}>

    </div>
}