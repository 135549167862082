import * as React from 'react'
import * as Styles from './styles.module.scss'
import logo from './logo.png'
// import './bai-logo-horizontal.png'
console.log("img", logo)

export const Header = (props) => {
    return <div className={Styles.header}>
        <img src={logo}></img>
    </div>
}