import { createContext } from "react";
import { Entity, entity } from 'simpler-state'
import { questionBase_i, question_i } from "src/types";
import { questions } from "./question";


export class SurveyModel{
    progress: Entity<number> = entity(0)
    progressPercent: Entity<number> = entity(0)
    answers: Map<string, string> = new Map()
    maxProgress: number
    questions: QuestionModel<question_i>[]
    constructor(public _questions: question_i[]){
        this.questions = this._questions.map((question)=>new QuestionModel(this, question))
        this.updateMaxProgress()
    }



    getQuestionPos(id: string){
        const pos = this._questions.map((q)=>{return q.id}).indexOf(id)
        return pos
    }

    getQuestion(id: string){
        return this._questions[this.getQuestionPos(id)]
    }

    getNextQuestion(id: string){
        return this._questions[this.getQuestionPos(id)+1]
    }

    updateProgress(){
        let progress = 0
        this.questions.forEach((q)=>{
            if(q.progress && this.answers.has(q.id)){
                progress += 1
            }
        })
        this.updateMaxProgress()
        this.progress.set(progress)
        this.progressPercent.set(Math.round(progress/this.maxProgress*100))
    }
    updateMaxProgress(){
        this.maxProgress = this.questions.filter((q)=>{
            return q.progress!=false&&q.shown.get()
        }).length
    }
    updateAnswer(id: string, value: string){
        this.answers.set(id, value)
        console.log(id, value, this.answers.get(id))
        this.questions.forEach((q)=>{
            q.conditional()
        })
    }
    
    scroll(id: string){
        // const nextQuestion = this.getNextQuestion(id)
        // if(!nextQuestion) return
        const element = document.getElementById(id)
        if(!element) return
        const nextElement = element.nextSibling
        if(!nextElement) return
        // @ts-ignore
        nextElement.scrollIntoView({behavior: "smooth"})
    }

    submit = (e) => {
        e.preventDefault()
        console.log(this.answers)
    }
}


export class QuestionModel<T extends questionBase_i>{
    shown: Entity<boolean> = entity(true)
    id: string
    type: question_i['type']
    text: string
    progress: boolean
    constructor(public _survey: SurveyModel, public question: T){
        this.id = question.id
        this.type = question.type
        this.text = question.text
        this.progress = question.progress ?? true
    }


    updateAnswer(value: any){
        this._survey.updateAnswer(this.id, value)
        if(this.question.progress != false){
            this._survey.updateProgress()
        }
        if(this.question.jump != false){
            this._survey.scroll(this.id)
        }
    }

    onClick(){
        return (e: any) => {
            this.updateAnswer(e.target.value)
        }
    }

    onChange(){
        return (e: any) => {
            this.updateAnswer(e.target.value)
        }
    }

    conditional(){
        if(this.question.conditional){
            this.shown.set(this.question.conditional(this._survey))
        }
    }
}


export const survey = new SurveyModel(questions)