import { question_i } from "src/types";
import { survey } from "./Survey";

export const questions: question_i[] = [
    {
        "type": "likert",
        "id": "q1",
        "text": "How often do you travel on Toronto transit buses?",
        "options": [
            "Daily",
            "3-5 times per week",
            "1-2 times per week",
            "Sometimes",
            "Rarely",
        ],
        "jump": true,
    },
    {
        "type": "likert",
        "id": "q2",
        "text": "Please rate your experience using our TCONNECT Wi-Fi service on the bus:",
        // "range": {
        //     min: 1,
        //     max: 5
        // }
        "options": [
            "1",
            "2",
            "3",
            "4",
            "5",
        ],
    },
    {
        "type": "likert",
        "id": "q3",
        "text": "On a scale of ‘extremely easy’ to ‘extremely difficult’, how easy was it to access our TCONNECT Wi-Fi service on the bus?",
        "options": [
            "Extremely easy",
            "Somewhat easy",
            "Neither easy nor difficult",
            "Somewhat difficult",
            "Extremely difficult",
        ],
        "jump": true,
    },
    {
        "type": "likert",
        "id": "q4",
        "text": "Having Wi-Fi available on TTC buses would encourage me to take public transit more often?",
        "options": [
            "Disagree",
            "Neutral (no change to riding habits)",
            "Agree",
        ],
        "jump": true,
        conditional: (survey)=>{
            const answer = survey.answers.get("q1")

            return answer !== "Daily"
            
            // TODO
            // - survey.onClick should save the answer
            // - check if q1.value == 'Daily'
            // return true
        }
    },
    {
        "type": "likert",
        "id": "q5",
        "text": "How much more often would you take the TTC if Wi-Fi was available on all bus routes?",
        "options": [
            "1 more day per week",
            "2 more days per week",
            "3+ more days per week",
        ],
        "jump": true,
        conditional: (survey)=>{
            const q1Answer = survey.answers.get("q1")
            const q4Answer = survey.answers.get("q4")

            return q1Answer !== "Daily" && q4Answer != "Neutral (no change to riding habits)"
        }
    },
    {
        "type": "checkbox",
        "id": "q6",
        "text": "Being able to use TCONNECT Wi-Fi on TTC bus routes would (select all that apply):",
        "options": [
            "Make my commute more productive ",
            "Make me feel safer commuting",
            "Enable contact with friends/family",
            "Make my commute less enjoyable",
        ],
        "jump": false,
        "progress" : false,

    },
    {
        "type" : "textarea",
        "id" : "q7",
        "text" : "If you would like to add any additional comments, please leave them in the box below:",
        "jump" : false,
        "progress" : false
    }
]