import React from 'react'
import { QuestionModel, survey } from 'src/models/Survey'
import { questionLikert_i, questionRange_i } from 'src/types'
import { Likert } from './Likert'

export const RangeInput = (props: { question: QuestionModel<questionRange_i> }) => {
    const { question } = props
    const {range, ...rest} = question.question
    const {min, max} = range
    const newQuestion = new QuestionModel<questionLikert_i>(survey, {
        ...rest,
        type: "likert",
        options: Array.from({length:max},(v,k)=>(k+min).toString())
    })
    return <Likert question={newQuestion}/>
}