import React from 'react'
import { QuestionModel, survey } from 'src/models/Survey'
import { questionLikert_i } from 'src/types'
import * as Styles from './styles.module.scss'

export const Likert = (props: { question: QuestionModel<questionLikert_i> }) => {
    const {question} = props
    const {options, id} = question.question

    if(!options) {
        console.warn("options must be defined for Likert input")
        return
    }
    return <div className={Styles.likert}>
        {options.map((value)=>(
            <div key={value}>
                <input type="radio" name={id} value={value} onClick={question.onClick()}/>
                <label>
                    {value}
                </label>
            </div>
        ))}
    </div>
}