import React from 'react'
import { ModalButton } from './Modal'
    
export const Description = (props) => {
    return <div className="description">
        The purpose of this survey is to get your feedback on our TCONNECT Wi-Fi service being piloted on select <strong>TTC bus routes.</strong> 
        <br />
        <br />
        Your responses will be handled in accordance with our <ModalButton>privacy policy.</ModalButton>
        <br />
        <br />
        You can view your progress on the left. Estimated time to complete is 2 minutes.
    </div>
}
