import * as React from 'react'
import { QuestionModel, survey } from 'src/models/Survey'
import { questionTextarea_i } from 'src/types'

import * as Styles from './styles.module.scss'

export const Textarea = (props: {question: QuestionModel<questionTextarea_i>}) => {
    const {question} = props

    return <textarea className={Styles.textarea} 
    placeholder="If you’d like to add any additional comments, please leave them here"
    onChange={question.onChange()}
    >

    </textarea>
}