import * as React from 'react'
import { question_i } from 'src/types'
import { Question } from 'src/components/Question'
import { HR } from 'src/components/HR'
import { survey } from 'src/models/Survey'

import * as Styles from './styles.module.scss'




export const Survey = (props) => {
    const questions = survey.questions
    let index = 0
    return <form>
        {questions.map((question) => {
            if(question.shown.use() == false) return null
            index += 1
            return <div key={question.id}>
                <Question question={question} index={index} />
                <HR />
            </div>
        })}

        <button className={Styles.submit} onClick={survey.submit}>
            Submit
        </button>
        <div className={Styles.empty} />
    </form>
}