import * as React from 'react'
import { question_i } from 'src/types'
import { Radio, Textarea, Checkbox, Likert, RangeInput } from 'src/components/inputs'

import * as Styles from './styles.module.scss'
import { survey, QuestionModel } from 'src/models/Survey'

const inputs = {
    'textarea': Textarea,
    'radio': Radio,
    'checkbox': Checkbox,
    'likert' : Likert,
    'range' : RangeInput
}

export const Question = (props: { question: QuestionModel<question_i>, index: number }) => {
    const { question, index } = props
    const {id} = question
    const Elem: any = inputs[question.type]


    // if(question.shown.use() == false) return null


    return <div className={Styles.question} id={id}>
        <label>
            {index}. {question.text}
        </label>
        <Elem question={question}/>
    </div>
}