import React from 'react'
import { survey } from 'src/models/Survey'
import * as Styles from './styles.module.scss'

export const ProgressBar = (props) => {
    const percent = survey.progressPercent.use()
    return <div className={Styles.ProgressBar}>
        <div className={Styles.progress} style={{
            height: `${percent}vh`,
        }}>
        </div>
    </div>
}