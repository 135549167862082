import React, { useContext } from 'react'
import { state } from 'src/models'
import { HR } from '../HR'
import close from './close.svg'
import * as Styles from './styles.module.scss'


export const PrivacyModal = (props) => {
    const privacyModalOpen = state.privacyModalOpen.use()
    const cls = privacyModalOpen ? Styles.modal : Styles.closed

    return <div className={cls}>
        <div className={Styles.innerModal}>
            <div className={Styles.closeWrapper}>
                <img className={Styles.close} onClick={state.toggleModal} src={close} />
            </div>
            <HR/>
            <div className={Styles.content}>
                <h1 id="privacy-policy">Privacy Policy</h1>
                <h2 id="what-data-does-this-privacy-policy-cover-">What data does this Privacy Policy cover?</h2>
                <p>BAI Communications Inc. (&quot;BAI&quot; / &quot;we&quot; / &quot;us&quot;) respects the privacy and personal electronic communication of the users of the BAI Services (as defined below). This Privacy Policy describes BAI&#39;s policy with respect to:</p>
                <ul>
                    <li>Personally Identifiable Information (&quot;Personal Information&quot; or &quot;PII&quot;) and other information that is collected from users of the BAI Services with their express consent, and can be used to identify an individual. This Privacy Policy also describes the collection and use of data by BAI, which, by itself, cannot be reasonably used to identify an individual person, but could possibly in future, with additional information, be linked with personally identifiable information. This includes:</li>
                    <li>De-Personalized Data, which includes data, which can be associated to a particular mobile device, but cannot be reasonably used to infer information about a particular individual.</li>
                    <li>De-Identified Data, which refers to data, which cannot be reasonably used to infer information about a specific user, computer, or device. This includes aggregated data, statistical samples, and the like.</li>
                </ul>
                <p>As such, the obligations under this Privacy Policy do not apply to such information. Nonetheless, we provide information in this Privacy Policy about the collection of such information, and treat such information with the same processes and safeguards as if it were personally identifiable information.</p>
                <p>What services this Privacy Policy apply to?</p>
                <p>For purposes of this Privacy Policy, the &quot;BAI Services&quot; include</p>
                <p>a. the Internet services operated by BAI, commonly known as &quot;TCONNECT&quot;
                    b. BAI&#39;s related captive portal, website and other BAI websites (collectively, &quot;Site&quot;) and
                    c. all software developed and made available by BAI in connection with any of the foregoing.</p>
                <p>Any new features added to or augmenting the BAI Services are also subject to this Privacy Policy.
                    This Privacy Policy does not apply to any Personal Information collected by BAI other than information collected through the BAI Services. This Privacy Policy will not apply to any unsolicited information you provide to BAI through the BAI Services or through any other means. All information you provide that is not in response to a request or solicitation from BAI will be considered non-confidential, and BAI will be free to reproduce, use, disclose, and distribute such unsolicited information to others without limitation or attribution.
                    This Privacy Policy does not apply to any Personal Information collected by BAI other than information collected through the BAI Services. This Privacy Policy will not apply to any unsolicited information you provide to BAI through the BAI Services or through any other means. All information you provide that is not in response to a request or solicitation from BAI will be considered non-confidential, and BAI will be free to reproduce, use, disclose, and distribute such unsolicited information to others without limitation or attribution.
                    In many cases, BAI services are offered anonymously, without the need to log in or provide any information that can be tied to your personal identity. In these circumstances, the information collected (to operate the network, better understand how our customers use the network, improve your experience, or better understand user behavior) is not personalized or tied to your individual identity, and, though treated as such, should not be considered personally identifiable information.</p>
                <h1 id="do-i-need-to-consent-to-this-privacy-policy-to-use-the-tconnect-service-">Do I need to consent to this Privacy Policy to use the TCONNECT service?</h1>
                <p>Yes. By connecting to the service, and/or providing us with Information, you are consenting to our use of it in accordance with this Privacy Policy. If you do not agree to this Privacy Policy, you may not use the BAI Services.
                    Please note that the BAI Services are intended for adult audiences and BAI does not knowingly collect any Personal Information from anyone under 13 years of age.
                    Because our business may change, we reserve the right to update or make changes to this Privacy Policy at any time without prior notice. Please check back from time to time to ensure that you are aware of these changes. This Privacy Policy was last updated on the date indicated above. Your continued use of the BAI Services will signify your acceptance of these changes.</p>
                <h1 id="what-information-does-bai-collect-">What information does BAI collect?</h1>
                <p>As you visit our locations and use our services, we collect information about your device and the services you use. The information we collect may be obtained from different sources, including (1) information you give us; (2) information we collect automatically; (3) information we collect from 3rd-party sources; and (4) information we infer or derive from analysis of the above.</p>
                <ol>
                    <li>Information you give us is only collected following your explicit consent, such as your name, e-mail address, mailing address, phone number, payment information. Other information you provide may be unsolicited by us, such as when you contact us via e-mail.</li>
                    <li>Information we automatically collect includes information we use to maintain our systems, optimize performance, and improve our services. This include network statistics, such device and usage information, location information, the amount and types of data you transfer, how you connect, how long you are connected, the services you use, and the advertisements you see and interact with.</li>
                    <li>We may use the information collected to derive a better understanding of the users of our service, and infer certain characteristics about them. We use this analysis to help in the development of services, features, and advertisements, which may better appeal to our users.</li>
                </ol>
                <p>BAI and its affiliates also collect and use the following type of data:
                    Cookies. BAI may use &quot;cookies,&quot; in order to identify returning users and expedite your reconnection to the network. Cookies are files that can contain specific user information, such as User ID, or they can be used to maintain a connection with you during your visit (so that you can visit pages of a site without retyping the same information over and over). BAI uses cookies to track customer traffic patterns, to maintain a connection during your visit with us, to track your preferences, to diagnose problems on our servers and administer the BAI Services. The BAI Services may make use of a session cookie which expires after a few minutes of inactivity and is automatically deleted from your device.
                    Our advertising partners may also make use of cookies. They use cookies to better understand how you use websites, to make sure ads are served correctly, and for analytics and reporting purposes. For example, a cookie may be utilized to limit exposure to specific ads. The information stored in our partners&#39; cookies is not linked to any personally identifiable information.
                    The Privacy Policy applies to cookies placed on your device by us, but does not cover the use of cookies by any third-party web advertisers. For the privacy practices of such third-party web advertisers, you should consult the applicable privacy policy for the relevant third-party web advertiser(s).
                    If you are concerned about cookies, it is possible to turn them off in your web browser preferences. However, please be aware that by disabling cookies, your web surfing experience will be degraded.
                    MAC Addresses. The BAI Services may also record and retain the MAC (Media Access Controller) address from your device to enable BAI to accurately perform administrative and operational functions and otherwise provide the BAI Services.
                    MAC addresses are hashed when stored, and are never shared in plain-text format. An anonymized &quot;opaque identifier&quot; is derived by hashing the MAC address. This unique key cannot personally identify you, nor can the original MAC address be derived by reverse-engineering.</p>
                <h1 id="how-does-bai-use-the-information-it-collects-">How does BAI use the information it collects?</h1>
                <p>To manage, monitor, optimize and ensure the integrity of the services we provide to you. Usage statistics, load factors, incidents, feedback, fair use monitoring, and the like, are all used to make technical improvements to the network and ensure that the service is operating adequately for the benefit of all users.
                    To help us improve the content and functionality of the BAI Services, and enable features. Certain features, functionality, and distinctions in service may only be made available to customers who qualify, purchase, or opt in for enhanced services. In these cases, it will be essential to differentiate these qualified users from those enjoying the standard level of service.
                    To understand the network and its use by customers. By collecting information about use of the network, including where it is used, for how long, and what it is used for, BAI can improve our Services to better match our users&#39; behaviours and interests, offer new features that are of value to our end users, and provide valuable insights to the transit authority&#39;s customers that can be used to improve their services. We may also share this information within the BAI Communications group for purposes consistent with this Privacy Policy. This includes BAI CommunicationsвЂ™ subsidiaries, as indicated at www.baicommunications.com.
                    To create personalized content, or to tailor services and advertising that may be of more interest to you, based on inferences derived from your use of the service.
                    To generate revenue. TCONNECT a service made available at no charge to end users, transit authorities, or taxpayers. Costs of installing and operating the network are offset through revenue generated by advertising and sponsorship. BAI uses de-personalized and de-identified data collected when you use the service to better target advertising, and thus increase the value of advertising opportunities to our partners.
                    To manage advertisements: Advertisements appearing through the BAI Services may be delivered by us or one or more third-party web advertisers. These third-party web advertisers may set cookies. Cookies allow the advertising server operated by that third party to recognize your device each time they send you an online advertisement. Accordingly, advertisement servers may compile information about where or whether you viewed their advertisements and which advertisements you clicked on. This information allows web advertisers to deliver targeted advertisements that they believe will be of most interest to you.
                    To generate usage statistics. BAI may also compile device information and usage statistics such as usage patterns, travel patterns and website page views. BAI may use this information for statistical purposes to improve the BAI Services and to manage our networks and systems more efficiently. The personal identities of users cannot be identified from these reports.
                    To detect potential fraud, abuse, unlawful use, and other violations of our Terms of Service. On occasion, it may be necessary to record and trace individual usage and/or personal or technical information in order to maintain the integrity and security of the BAI Services. In order to protect our users and maintain a high level of service, BAI will use commercially reasonable efforts to track and identify any malicious user acting in contravention of our Terms of Service. To do so, BAI or its representatives may track and record any information that may be considered evidence of misuse. Please refer to the TCONNECT Terms of Service for more information.
                    To contact you in the future, to tell you about products or services we believe will be of interest to you, but only with your explicit consent. BAI does not share your e-mail address or other Personal Information, such as name, address, gender or age, with third parties for marketing purposes without your prior consent. In other words, we don&#39;t sell customer lists.
                    To provide you support and customer service. For instance, if you contact us by email, we will use the Information you provide to answer your question or resolve your problem.
                    To comply with the law. BAI may provide data to law enforcement to comply with a production order or other legal requirement. Otherwise, we only share data, as required, to act in the interests of personal or public safety, or national security, if we believe in good faith that such exigent circumstances exist.</p>
                <h1 id="where-is-my-information-stored-">Where is my information stored?</h1>
                <p>If you provide Information to the BAI Services, your information may be transferred from your current location to the offices and servers of BAI and authorized third parties, including commercial &quot;cloud storage services&quot;, referred to herein located in Canada, the United States or other countries.</p>
                <h1 id="how-long-does-bai-keep-my-information-">How long does BAI keep my information?</h1>
                <p>We will keep your information for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you. We may retain certain data as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally-identifiable data, account recovery, or if required by law. All retained information is anonymized, and will remain subject to the terms of this Privacy Policy. If you request that your name be removed from our databases, it may not be possible to completely delete all your Information due to technological and/or legal constraints.</p>
                <h1 id="what-is-bai-policy-regarding-links-to-other-sites-">What is BAI policy regarding links to other sites?</h1>
                <p>You should be aware that while you are using the BAI Services, you will access other sites that are beyond our control. Because we cannot control the privacy practices of these websites, we recommend reading the privacy policy of the website you link to before you submit any of your Personal Information. The links from the BAI Services do not imply that BAI endorses or has reviewed such third-party sites.</p>
                <h1 id="opting-out">Opting Out</h1>
                <p>BAI Services are offered at no charge to end users or taxpayers; to the contrary, BAI pays a fee for the right to operate wireless services in the subway system. In order to help offset these costs, BAI uses sponsorship and targeted advertising to generate revenue, so that the operation of these services can continue to remain free of charge to taxpayers and transit riders. Should you wish to opt out of BAI Services, BAI and TCONNECT provide a number of options to allow you to control how your information is used:</p>
                <ol>
                    <li>Use cellular data. Currently, Freedom Mobile customers can use phone and data services in all TTC underground stations, which are not subject to any inline advertising from BAI or its partners. If you are a customer of another carrier, call your carrier and tell them you want to be able to use your mobile device features in the Toronto transit underground.</li>
                    <li>Disable cookies. To remove consent for the use of cookies by BAI and its partners, it is possible to turn them off in your web browser preferences. However, please be aware that by disabling cookies, your web surfing experience will be degraded. Please refer to your web browser&#39;s settings to manage website cookies.</li>
                    <li>Use your device&#39;s settings to opt out of targeted advertising. Modify the settings on your mobile device to take advantage of built-in features to disable targeted advertising. See <a href="https://www.networkadvertising.org/mobile-choices">https://www.networkadvertising.org/mobile-choices</a> for more information and step-by-step instructions for your device type. Please note that you will continue to see ads - they will only be less relevant to you, as they will not be based on your selections, preferences, or browsing habits.</li>
                    <li>Use AdChoices to opt out of targeted advertising. BAI&#39;s advertising partners are member of the AdChoices Digital Advertising Alliances, and they respect the preferences you declare using AdChoices controls. You can select the types of ads you wish to see using the controls offered on the AdChoices website, or using a downloadable mobile app. Learn more about AdChoices here: <a href="http://youradchoices.com/learn">http://youradchoices.com/learn</a> Configure your ad choices here: <a href="http://youradchoices.com/control">http://youradchoices.com/control</a> Please note that you will continue to see ads - they will only be less relevant to you, as they will not be based on your selections, preferences, or browsing habits.</li>
                    <li>Opt out of Wi-Fi Analytics and BAI direct advertising. BAI may use Wi-Fi detection in order to offer location-based services to transit riders, and improve transit operations through the analysis of movement patterns of transit riders. Wi-Fi analytics may also be used in exigent circumstances for public safety and security purposes.</li>
                </ol>
                <p>BAI may also acquire sponsors and generate its own direct advertising, which can be made more relevant to you through a better understanding of your online activities.
                    If you do not wish to partake in such activities, you may opt out. If you wish to withdraw your consent to Wi-Fi detection and/or direct advertising from BAI and BAI sponsors, please send an e-mail to privacy@baicanada.com that includes the MAC address of your mobile device or computer.
                    Please note that this opt-out removes your device ID only for the purposes of analytics, marketing and advanced features offered to transit riders; it may not be possible to completely delete all your Information due to technological and/or legal constraints. Also note that:</p>
                <ul>
                    <li>you may not be able to take advantage of certain optional features offered to transit riders, either now or in the future</li>
                    <li>you will continue to see advertisements - they may just not be as relevant to you.</li>
                </ul>
                <p>If you continue to use BAI Services, MAC address detection and storage will continue to be used for the purposes of delivering network services, and ensuring the security, performance and integrity of the BAI network.</p>
                <h1 id="if-you-have-questions-">If you have questions...</h1>
                <p>If you have any questions or comments about this Privacy Policy, please contact us at privacy@baicanada.com; or by regular mail at BAI Communications Inc., Attn: Privacy Officer, to BAI&#39;s main offices located at 33 Bloor St. East, Suite 301, Toronto, Ontario, M4W 3H1, Canada.</p>

            </div>
        </div>
    </div>
}