import * as React from 'react'
import { QuestionModel } from 'src/models/Survey'
import { questionRadio_i } from 'src/types'


export const Radio = (props: { question: QuestionModel<questionRadio_i> }) => {
    const { question } = props

    return <div> 
    </div>
}