import * as React from 'react'
import * as Styles from './styles.module.scss'
import { QuestionModel, survey } from 'src/models/Survey'
import { questionCheckbox_i } from 'src/types'


export const Checkbox = (props: {question: QuestionModel<questionCheckbox_i>}) => {
    const {question} = props
    const {options, id} = question.question

    const [selected, setSelected] = React.useState([])
    React.useEffect(()=>{
        question.updateAnswer(selected)    
    }, [selected])

    const onClick = (e) => {
        let newSelected = []
        if(e.target.checked){
            newSelected = [...selected, e.target.value]
        }else{
            newSelected = selected.filter((str) => {
                return str !== e.target.value
            })
        }
        setSelected(newSelected)
    }


    return <div>
        {options.map((value)=>(
            <div key={value} className={Styles.checkbox}>
                <label>
                    <input type="checkbox" name={id} value={value} onClick={onClick}/>
                    {value}
                </label>
            </div>
        ))}
    </div>
}